import { Pagination } from 'antd/lib'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  OfrBlock,
  OfrFormContainer,
  OfrFormPageContainer,
  SectionLoadingState,
} from '../../components'
import { AnnotationInfo } from '../../components/OfrBlock'
import { ofrBlockTypes } from '../../constants/constants'
import {
  useOfrDocumentTemplateDetails,
  useOfrDocumentTemplateImages,
} from '../../hooks'
import { ofrDocumentTemplateService } from '../../services'
import notificationService from '../../services/notificationService'
import {
  OfrBlockInfo,
  OfrDocumentTemplate,
  OfrDocumentTemplatePage,
  OFrImage as OfrImage,
  setSelectedOfrDocumentTemplate,
} from '../../store/reducers/ofrDocumentTemplateReducer'
import OfrDocumentTemplateDetailsPageHeader from './components/OfrDocumentTemplateDetailsPageHeader'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 100px);
  margin-top: -64px;
`

const PaginationContainer = styled.div`
  margin: 16px;
`

const OfrSelection = styled.div`
  border: 1px solid #cccccc;
  position: absolute;
`

export interface OfrBlockInfoExtended extends OfrBlockInfo {
  isNewBlock?: boolean
  isSelected?: boolean
}

interface ImageMouseEventInfo {
  event: MouseEvent<HTMLImageElement>
  imageId: string
  x: number
  y: number
}

interface BlockToDelete {
  id: string
  pageId: string
}

export interface OfrDocumentTemplatePageExtended
  extends OfrDocumentTemplatePage {
  extendedBlocks?: OfrBlockInfoExtended[]
}

const OfrDocumentTemplateDetailsPage = () => {
  const { documentId } = useParams()
  const imageRefs = useRef<(HTMLImageElement | null)[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedOfrDocumentTemplate, refreshOfrDocumentTemplateDetails } =
    useOfrDocumentTemplateDetails({
      documentId,
      preventFetch: true,
    })
  const {
    selectedOfrDocumentTemplateImages,
    refreshOfrDocumentTemplateImages,
  } = useOfrDocumentTemplateImages({
    documentId,
    preventFetch: true,
  })

  const [pageNumber, setPageNumber] = useState(1)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [fetchDone, setFetchDone] = useState(false)

  const [documentPagesExtended, setDocumentPagesExtended] = useState<
    OfrDocumentTemplatePageExtended[]
  >([])
  const [blocksToDelete, setBlocksToDelete] = useState<BlockToDelete[]>([])

  const [mouseDownInfo, setMouseDownInfo] = useState<
    ImageMouseEventInfo | undefined
  >(undefined)
  const [mouseMoveInfo, setMouseMoveInfo] = useState<
    ImageMouseEventInfo | undefined
  >(undefined)
  const [isMouseDown, setIsMouseDown] = useState(false)

  useEffect(() => {
    if (!fetchDone) {
      refreshOfrDocumentTemplateDetails()
      refreshOfrDocumentTemplateImages()
      setFetchDone(true)
    }
  }, [
    fetchDone,
    refreshOfrDocumentTemplateDetails,
    refreshOfrDocumentTemplateImages,
  ])

  useEffect(() => {
    if (selectedOfrDocumentTemplate?.id !== documentId) {
      setIsImageLoaded(false)
      setFetchDone(false)
    }
  }, [documentId, selectedOfrDocumentTemplate])

  const updateExtendedPages = useCallback(() => {
    const originalPages: OfrDocumentTemplatePageExtended[] =
      selectedOfrDocumentTemplate?.formPages || []
    const extendedList = originalPages.map(
      (page) =>
        ({
          ...page,
          extendedBlocks: page.blocks.map(
            (block, index) =>
              ({
                ...block,
                id: block.id ?? index.toString(),
              } as OfrBlockInfoExtended)
          ),
        } as OfrDocumentTemplatePageExtended)
    )

    return extendedList
  }, [selectedOfrDocumentTemplate])

  useEffect(() => {
    if (!!selectedOfrDocumentTemplate) {
      const updatedExtendedList = updateExtendedPages()
      setDocumentPagesExtended(updatedExtendedList)
    }
  }, [selectedOfrDocumentTemplate, updateExtendedPages])

  const unSelectBlocks = useCallback(() => {
    const newDocuments = documentPagesExtended.map((document) => {
      const newBlocks = document.extendedBlocks?.map((block) => ({
        ...block,
        isSelected: false,
      }))
      return { ...document, extendedBlocks: newBlocks }
    })
    setDocumentPagesExtended(newDocuments)
  }, [documentPagesExtended])

  const handleImageMouseDown = useCallback(
    (e: MouseEvent<HTMLImageElement>, imageId: string) => {
      unSelectBlocks()
      setIsMouseDown(true)
      const rect = imageRefs.current[pageNumber - 1]?.getBoundingClientRect()

      setMouseDownInfo({
        event: e,
        imageId: imageId,
        x: (e.clientX ?? 0) - (rect?.left || 0),
        y: (e.clientY ?? 0) - (rect?.top || 0),
      })
    },
    [unSelectBlocks, pageNumber]
  )

  const handleImageMouseMove = useCallback(
    (e: MouseEvent<HTMLImageElement>, imageId: string) => {
      if (isMouseDown) {
        const rect = imageRefs.current[pageNumber - 1]?.getBoundingClientRect()
        setMouseMoveInfo({
          event: e,
          imageId: imageId,
          x: (e.clientX ?? 0) - (rect?.left || 0),
          y: (e.clientY ?? 0) - (rect?.top || 0),
        })
      }
    },
    [isMouseDown, pageNumber]
  )

  const handleImageMouseUp = useCallback(
    (e: MouseEvent<HTMLImageElement | HTMLDivElement>, imageId: string) => {
      setIsMouseDown(false)
      const rect = imageRefs.current[pageNumber - 1]?.getBoundingClientRect()
      const minimumLeftMargin = 40
      const minimumBlockSize = 5
      const x1 = Math.max(mouseDownInfo?.x || 0, minimumLeftMargin)
      const y1 = mouseDownInfo?.y || 0
      const x2 = Math.max(
        (e.clientX ?? 0) - (rect?.left || 0),
        minimumBlockSize
      )
      const y2 = (e.clientY ?? 0) - (rect?.top || 0)
      const isBlockBigEnough =
        Math.abs(x1 - x2) > minimumBlockSize &&
        Math.abs(y1 - y2) > minimumBlockSize

      if (selectedOfrDocumentTemplate && isBlockBigEnough) {
        const newBlock: OfrBlockInfoExtended = {
          id: ofrDocumentTemplateService.getBlockNextId({
            ofrDocumentTemplatePages: documentPagesExtended,
            pageId: imageId,
          }),
          isNewBlock: true,
          cell: {
            x1,
            y1,
            x2,
            y2,
            isGhost: false,
          },
          htrTexts: [],
          ocrTexts: [],
          isCheckbox: false,
          containsWriting: false,
          hasStrikeThrough: false,
          hasStrikeThroughText: false,
          ofrBlockName: '',
          ofrBlockType: ofrBlockTypes.NONE,
        }

        const copyDocumentPagesExtended: OfrDocumentTemplatePageExtended[] =
          JSON.parse(JSON.stringify(documentPagesExtended))
        const pageIndex = copyDocumentPagesExtended.findIndex(
          (page) => page.id === imageId
        )
        if (copyDocumentPagesExtended[pageIndex].extendedBlocks) {
          copyDocumentPagesExtended[pageIndex].extendedBlocks?.push(newBlock)
        }

        setDocumentPagesExtended(copyDocumentPagesExtended)
      }
      setMouseDownInfo(undefined)
      setMouseMoveInfo(undefined)
    },
    [
      mouseDownInfo,
      selectedOfrDocumentTemplate,
      documentPagesExtended,
      pageNumber,
    ]
  )

  const handleBlockMouseUp = useCallback(() => {
    setIsMouseDown(false)
    setMouseDownInfo(undefined)
    setMouseMoveInfo(undefined)
  }, [])

  const handleSave = useCallback(
    (
      documentTemplate?: OfrDocumentTemplate,
      documentPagesExtended?: OfrDocumentTemplatePageExtended[]
    ) => {
      const newPages: OfrDocumentTemplatePage[] =
        documentPagesExtended?.map((documentExtended, pageIndex) => {
          const copyDocumentExtended: OfrDocumentTemplatePageExtended =
            JSON.parse(JSON.stringify(documentExtended))

          // Block with no cell are still part of what is saved, but are not used right now.
          copyDocumentExtended.blocks =
            copyDocumentExtended.extendedBlocks
              ?.filter(
                (block) =>
                  !blocksToDelete.some(
                    (blockToDelete) =>
                      blockToDelete.id === block.id &&
                      blockToDelete.pageId === copyDocumentExtended.id
                  )
              )
              .filter((block) => !!block.cell)
              .map((block) => {
                delete block.isNewBlock
                delete block.isSelected
                block.htrTexts = !!block.htrTexts ? block.htrTexts : []
                block.ocrTexts = !!block.ocrTexts ? block.ocrTexts : []
                return block as OfrBlockInfo
              }) || []
          delete copyDocumentExtended.extendedBlocks
          return copyDocumentExtended as OfrDocumentTemplatePage
        }) || []
      const copySelectedOfrDocumentTemplate: OfrDocumentTemplate = JSON.parse(
        JSON.stringify(documentTemplate)
      )
      copySelectedOfrDocumentTemplate.formPages = newPages

      ofrDocumentTemplateService
        .updateOfrDocumentTemplate({
          documentId: documentId || '',
          navigate,
          documentTemplate: copySelectedOfrDocumentTemplate,
        })
        .then((response: OfrDocumentTemplate) => {
          dispatch(setSelectedOfrDocumentTemplate(response))
          setBlocksToDelete([])
          notificationService.notificationSuccess(
            t('ofrDocumentTemplateDetailsPage.saveDocumentSuccessful') || ''
          )
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
        })
    },
    [blocksToDelete, dispatch, documentId, navigate]
  )

  const handleOnSelectedChange = useCallback(
    (
      value: boolean,
      blockIndex: number,
      documentPagesExtended: OfrDocumentTemplatePageExtended[],
      setDocumentPagesExtended: Function
    ) => {
      const newDocuments = documentPagesExtended.map((document) => {
        const newBlocks = document.extendedBlocks?.map((block, index) => ({
          ...block,
          isSelected: index === blockIndex ? value : false,
        }))
        return { ...document, extendedBlocks: newBlocks }
      })
      setDocumentPagesExtended(newDocuments)
    },
    []
  )

  const handleOnAnnotationChange = useCallback(
    (
      value: AnnotationInfo,
      pageIndex: number,
      blockIndex: number,
      documentPagesExtended: OfrDocumentTemplatePageExtended[],
      setDocumentPagesExtended: Function
    ) => {
      const copyDocumentPagesExtended: OfrDocumentTemplatePageExtended[] =
        JSON.parse(JSON.stringify(documentPagesExtended))
      const page = copyDocumentPagesExtended[pageIndex]

      if (page && page.extendedBlocks) {
        if (value.isAnnotated) {
          page.extendedBlocks[blockIndex].ofrBlockName = value.name
          page.extendedBlocks[blockIndex].ofrBlockType = value.blockType
          page.extendedBlocks[blockIndex].isCheckbox =
            value.blockType === ofrBlockTypes.CHECKBOX
        } else {
          page.extendedBlocks[blockIndex].ofrBlockName = ''
          page.extendedBlocks[blockIndex].ofrBlockType = ofrBlockTypes.NONE
          page.extendedBlocks[blockIndex].isCheckbox = false
        }
        copyDocumentPagesExtended[pageIndex] = page
      }

      setDocumentPagesExtended(copyDocumentPagesExtended)
    },
    []
  )

  const handleOnDeleteBlock = useCallback(
    (
      pageIndex: number,
      blockIndex: number,
      documentPagesExtended: OfrDocumentTemplatePageExtended[],
      setDocumentPagesExtended: Function,
      blocksToDelete: BlockToDelete[],
      setBlocksToDelete: Function
    ) => {
      const copyDocumentPagesExtended: OfrDocumentTemplatePageExtended[] =
        JSON.parse(JSON.stringify(documentPagesExtended))
      if (copyDocumentPagesExtended) {
        const extendedBlocks =
          copyDocumentPagesExtended[pageIndex].extendedBlocks
        const pageId = copyDocumentPagesExtended[pageIndex].id
        const blockId = extendedBlocks ? extendedBlocks[blockIndex].id : ''

        if (
          extendedBlocks &&
          extendedBlocks[blockIndex] &&
          extendedBlocks[blockIndex].isNewBlock
        ) {
          extendedBlocks.splice(blockIndex, 1)
          setDocumentPagesExtended(copyDocumentPagesExtended)
        } else {
          const isBlockToBeDeleted = blocksToDelete.find(
            (blockToDelete) =>
              blockToDelete.id === blockId && blockToDelete.pageId === pageId
          )
          if (isBlockToBeDeleted) {
            const newBlocks = blocksToDelete.filter(
              (blockToDelete) =>
                blockToDelete.id !== blockId || blockToDelete.pageId !== pageId
            )
            setBlocksToDelete(() => newBlocks)
          } else {
            setBlocksToDelete(() => [
              ...blocksToDelete,
              { id: blockId, pageId },
            ])
          }
        }
      }
    },
    []
  )

  const imageBlocks: JSX.Element | undefined = useMemo(() => {
    if (documentPagesExtended[pageNumber - 1]) {
      const pageExtended = documentPagesExtended[pageNumber - 1]
      const imageInfo: OfrImage | undefined = pageExtended.id
        ? !!selectedOfrDocumentTemplateImages
          ? selectedOfrDocumentTemplateImages?.find(
              (img) => img?.id === pageExtended?.id
            )
          : undefined
        : undefined
      return (
        <OfrFormPageContainer key={pageNumber}>
          <img
            src={imageInfo?.image}
            alt="form"
            ref={(el) => (imageRefs.current[pageNumber - 1] = el)}
            onLoad={() => setIsImageLoaded(true)}
            style={{ display: isImageLoaded ? 'block' : 'none' }}
            draggable="false"
            onMouseDown={(e) => handleImageMouseDown(e, imageInfo?.id || '')}
            onMouseMove={(e) => handleImageMouseMove(e, imageInfo?.id || '')}
            onMouseUp={(e) => handleImageMouseUp(e, imageInfo?.id || '')}
          />
          {selectedOfrDocumentTemplate &&
            isImageLoaded &&
            pageExtended.extendedBlocks?.map(
              (block, blockIndex) =>
                block.cell && // Block with no cell are not used or displayed right now.
                block.cell.x1 !== undefined && (
                  <OfrBlock
                    key={`${block.cell.x1}-${block.cell.y1}-${block.cell.x2}-${block.cell.y2}`}
                    x1={block.cell?.x1 || 0}
                    y1={block.cell?.y1 || 0}
                    x2={block.cell?.x2 || 0}
                    y2={block.cell?.y2 || 0}
                    initialBlockName={block.ofrBlockName}
                    initialBlockType={block.ofrBlockType}
                    onSelectedChange={(value) =>
                      handleOnSelectedChange(
                        value,
                        blockIndex,
                        documentPagesExtended,
                        setDocumentPagesExtended
                      )
                    }
                    selected={!!block.isSelected}
                    onAnnotationChange={(value: AnnotationInfo) =>
                      handleOnAnnotationChange(
                        value,
                        pageNumber - 1,
                        blockIndex,
                        documentPagesExtended,
                        setDocumentPagesExtended
                      )
                    }
                    onToBeDeletedChange={() =>
                      handleOnDeleteBlock(
                        pageNumber - 1,
                        blockIndex,
                        documentPagesExtended,
                        setDocumentPagesExtended,
                        blocksToDelete,
                        setBlocksToDelete
                      )
                    }
                    onMouseUp={handleBlockMouseUp}
                  />
                )
            )}
          {isMouseDown && !!mouseDownInfo?.x && (
            <OfrSelection
              style={{
                top: Math.min(mouseDownInfo.y, mouseMoveInfo?.y || 0),
                left: Math.min(mouseDownInfo.x, mouseMoveInfo?.x || 0),
                height: Math.abs(mouseDownInfo.y - (mouseMoveInfo?.y || 0)),
                width: Math.abs(mouseDownInfo.x - (mouseMoveInfo?.x || 0)),
              }}
              onMouseUp={(e) => handleImageMouseUp(e, mouseDownInfo.imageId)}
            />
          )}
        </OfrFormPageContainer>
      )
    }
  }, [
    selectedOfrDocumentTemplate,
    selectedOfrDocumentTemplateImages,
    documentPagesExtended,
    handleOnSelectedChange,
    handleImageMouseDown,
    handleImageMouseMove,
    handleImageMouseUp,
    handleOnAnnotationChange,
    handleOnDeleteBlock,
    handleBlockMouseUp,
    imageRefs,
    isImageLoaded,
    isMouseDown,
    mouseDownInfo,
    mouseMoveInfo,
    blocksToDelete,
    pageNumber,
  ])

  return (
    <>
      <OfrDocumentTemplateDetailsPageHeader
        onSave={() =>
          handleSave(selectedOfrDocumentTemplate, documentPagesExtended)
        }
      />
      {imageBlocks && selectedOfrDocumentTemplate?.formPages && (
        <>
          <OfrFormContainer
            style={{
              overflowY: imageBlocks && isImageLoaded ? 'auto' : 'hidden',
            }}
          >
            {imageBlocks}
          </OfrFormContainer>

          {isImageLoaded && (
            <PaginationContainer>
              <Pagination
                defaultCurrent={1}
                total={selectedOfrDocumentTemplate?.formPages?.length || 0}
                current={pageNumber}
                onChange={(value) => setPageNumber(value)}
                pageSize={1}
              />
            </PaginationContainer>
          )}
        </>
      )}
      {!imageBlocks ||
        (!isImageLoaded && (
          <SectionLoadingStateContainer>
            <SectionLoadingState />
          </SectionLoadingStateContainer>
        ))}
    </>
  )
}

export default OfrDocumentTemplateDetailsPage
