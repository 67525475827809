import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DocumentDetailsLayout, SectionLoadingState } from '../../components'
import { DocumentDetailsFormatted } from '../../components/DocumentDetailsLayout'
import { NO_DATA_PLACEHOLDER } from '../../constants/constants'
import i18n from '../../i18n/i18n'
import { RootState } from '../../store'
import {
  SearchResultItem,
  setSelectedSearchResultItem,
} from '../../store/reducers/searchResultInfoReducer'
import { routePaths } from '../RootPage'

const SearchDocumentDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()

  const searchResultData: SearchResultItem[] | undefined = useSelector(
    (state: RootState) => state.searchResultInfo.searchResult?.data
  )
  const selectedDocument: SearchResultItem | undefined = useSelector(
    (state: RootState) => state.searchResultInfo.selectedSearchResultItem
  )

  useEffect(() => {
    if (searchResultData && searchResultData.length > 0) {
      const searchResultItem = searchResultData.find(
        (searchResultItem) => searchResultItem._id === params.documentId
      )

      if (searchResultItem) {
        dispatch(setSelectedSearchResultItem(searchResultItem))
      }
    } else {
      navigate(routePaths.SEARCH)
    }
  }, [searchResultData, params, dispatch, navigate])

  const getDocumentIndex = useCallback(
    () =>
      selectedDocument && searchResultData
        ? searchResultData.indexOf(selectedDocument)
        : 0,
    [selectedDocument, searchResultData]
  )
  const getDocumentIndexText = useCallback(
    () =>
      selectedDocument && searchResultData
        ? getDocumentIndex() + 1
        : NO_DATA_PLACEHOLDER,
    [selectedDocument, searchResultData, getDocumentIndex]
  )

  const handlePreviousDocumentClick = () => {
    const lastIndex = searchResultData ? searchResultData.length - 1 : 0
    const documentIndex = getDocumentIndex()
    const newDocumentIndex = documentIndex > 0 ? documentIndex - 1 : lastIndex
    const newSelectedDocumentId = searchResultData
      ? searchResultData[newDocumentIndex]._id
      : ''
    navigate(`${routePaths.SEARCH_DOCUMENT_DETAILS}/${newSelectedDocumentId}`)
  }
  const handleNextDocumentClick = () => {
    const documentIndex = getDocumentIndex()
    const newDocumentIndex =
      searchResultData && documentIndex < searchResultData.length - 1
        ? documentIndex + 1
        : 0
    const newSelectedDocumentId = searchResultData
      ? searchResultData[newDocumentIndex]._id
      : ''
    navigate(`${routePaths.SEARCH_DOCUMENT_DETAILS}/${newSelectedDocumentId}`)
  }

  const formattedDocumentDetails: DocumentDetailsFormatted = useMemo(
    () => ({
      _id: selectedDocument?._id,
      authors: selectedDocument?.authors,
      dataSource: selectedDocument?.dataSource,
      originalPublicationDate: selectedDocument?.publicationDate,
      language: i18n.language,
      publicationName: selectedDocument?.publicationName,
      title: selectedDocument?.title
        ? typeof selectedDocument?.title === 'string'
          ? selectedDocument?.title
          : selectedDocument?.title[i18n.language]
        : NO_DATA_PLACEHOLDER,
      url: selectedDocument?.documentLink
        ? typeof selectedDocument?.documentLink === 'string'
          ? selectedDocument?.documentLink
          : selectedDocument?.documentLink[i18n.language]
        : NO_DATA_PLACEHOLDER,
      csv: selectedDocument?.csv,
    }),
    [selectedDocument]
  )

  return selectedDocument ? (
    <DocumentDetailsLayout
      handleNextDocumentClick={handleNextDocumentClick}
      handlePreviousDocumentClick={handlePreviousDocumentClick}
      getDocumentIndex={getDocumentIndexText}
      length={searchResultData?.length}
      document={formattedDocumentDetails}
      backUrl={routePaths.SEARCH}
    />
  ) : (
    <SectionLoadingState />
  )
}

export default SearchDocumentDetailsPage
