import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ListPageLayout,
  PageContentDropdown,
  SearchTable,
  SectionLoadingState,
  Spacer,
  SubHeader,
} from '../../components'
import { SearchTableRow } from '../../components/SearchTable'
import FilterBadge from '../../components/display/FilterBadge'
import { dateFormat } from '../../constants/constants'
import useLiteratureReviews from '../../hooks/useLiteratureReviews'
import i18n from '../../i18n/i18n'
import literatureReviewService from '../../services/literatureReviewService'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import { DataSource } from '../../store/reducers/dataSourceReducer'
import { LiteratureReview } from '../../store/reducers/literatureReviewReducer'
import {
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from '../LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import { routePaths } from '../RootPage'

const FloatLeftContainer = styled.div`
  display: flex;
  margin-left: auto;
  order: 2;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`

const BadgesContainer = styled.div`
  display: flex;
  gap: 4px;
`

const LiteratureReviewSearchDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loadingIds, setLoadingIds] = useState<string[]>([])

  const {
    selectedSearchQuery,
    selectedLiteratureReview,
    loading,
    updateSelectedLiteratureReview,
    exportLiteratureReviewSearchQueryCsv,
  } = useLiteratureReviews(params)

  const formattedData: SearchTableRow[] = useMemo(
    () =>
      selectedSearchQuery?.searchResults
        ? selectedSearchQuery?.searchResults.map((document, index) => {
            return {
              ...document,
              key: index.toString(),
              id: document?.id || '',
              title: document?.title,
              publicationName: document?.publicationName || '',
              authors: document?.authors,
              publicationDate: document?.publicationDate || '',
              included:
                selectedLiteratureReview?.includedSearchResults?.includes(
                  document?.id || ''
                ),
              documentLink: document?.documentLink,
              language: document.language || '',
              summaryStatus: document?.summaryStatus || '',
            }
          })
        : [],
    [selectedSearchQuery, selectedLiteratureReview?.includedSearchResults]
  )
  const dataSources = useSelector(
    (state: RootState) => state.dataSource.dataSourceList
  )
  const source: DataSource | undefined = useMemo(
    () =>
      dataSources.find(
        (source) =>
          source.id ===
          literatureReviewService.getSearchQuerySourceId(
            selectedSearchQuery?.searchSource
          )
      ),
    [dataSources, selectedSearchQuery]
  )
  const sourceText = useMemo(
    () =>
      typeof source?.title === 'string'
        ? source?.title
        : source?.title[i18n.language],
    [source]
  )

  const appendLoadingId = (recordId: string) => {
    const allLoadingIds = [...loadingIds, recordId]
    setLoadingIds(allLoadingIds)
  }

  const removeLoadingId = (recordId: string) => {
    const allLoadingIds = loadingIds.filter((id) => id !== recordId)
    setLoadingIds(allLoadingIds)
  }

  const handleOnSwitchClick = async (recordId: string) => {
    appendLoadingId(recordId)
    literatureReviewService
      .saveSearchQueryInclusion({
        literatureReviewId: selectedLiteratureReview?.id || '',
        includedSearchResults:
          selectedLiteratureReview?.includedSearchResults || [],
        recordId,
        navigate,
      })
      .then((response: LiteratureReview) => {
        updateSelectedLiteratureReview(response)
        removeLoadingId(recordId)
        notificationService.notificationSuccess(
          t('literatureReviewSearchDetailsPage.saveDocumentSuccessful') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        removeLoadingId(recordId)
      })
  }

  const handleExport = () => {
    exportLiteratureReviewSearchQueryCsv(
      selectedLiteratureReview?.id || '',
      selectedSearchQuery?.searchEvent || ''
    )
  }

  const handleOnRowClick = (recordId: string) => {
    navigate(
      `${routePaths.LITERATURE_REVIEW_DOCUMENT}/${selectedLiteratureReview?.id}/${params.searchId}/${recordId}`
    )
  }

  return loading ? (
    <SectionLoadingState />
  ) : (
    <ListPageLayout>
      <SubHeader>
        <BackButton
          tooltipMessage={
            t('literatureReviewSearchDetailsPage.backButtonTooltipMessage') ||
            ''
          }
          url={`${routePaths.LITERATURE_REVIEW_DETAILS}/${selectedLiteratureReview?.id}/${literatureReviewPanelTypes.OVERVIEW}/${literatureReviewTabs.QUERIES}`}
        />
        <div>
          {t('Query')}: {selectedSearchQuery?.searchText}
        </div>
        <Spacer />
        <FloatLeftContainer>
          <div>
            <Moment
              date={selectedSearchQuery?.searchDate}
              format={dateFormat.PRIMARY}
            />
          </div>
          <BadgesContainer>
            {source && <FilterBadge color="default">{sourceText}</FilterBadge>}
            {selectedSearchQuery?.searchParameters.date && (
              <FilterBadge color="success">
                {selectedSearchQuery?.searchParameters.date}
              </FilterBadge>
            )}
          </BadgesContainer>
          <div>
            {t('Result total')}: {selectedSearchQuery?.searchResults.length}
          </div>
          <PageContentDropdown onExport={handleExport} />
        </FloatLeftContainer>
      </SubHeader>
      <SearchTable
        formattedData={formattedData}
        useInclusionToggle
        onSwitchClick={(recordId) => handleOnSwitchClick(recordId)}
        loadingIds={loadingIds}
        onClick={(recordId) => handleOnRowClick(recordId)}
        maxHeight="calc(100vh - 180px)"
      />
    </ListPageLayout>
  )
}

export default LiteratureReviewSearchDetailsPage
