import { AxiosError } from 'axios'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/constants'
import { apiService } from '../services'
import literatureReviewService from '../services/literatureReviewService'
import { RootState } from '../store'
import {
  Document,
  LiteratureReview,
  SearchQuery,
  setDetailsCsv,
  setListCsv,
  setLiteratureReviewList,
  setLiteratureReviewLoading,
  setSearchQueryCsv,
  setSelectedLiteratureReview,
  setSelectedSearchQuery,
  setSelectedSearchResultItem,
} from '../store/reducers/literatureReviewReducer'
import useDownloadFile from './useDownloadFile'

interface UseLiteratureReviewProps {
  literatureReviewId?: string
  searchId?: string
  documentId?: string
  preventFetch?: boolean
}

const useLiteratureReviews = ({
  literatureReviewId,
  searchId,
  documentId,
  preventFetch = false,
}: UseLiteratureReviewProps) => {
  const dispatch = useDispatch()
  const downloadCsv = useDownloadFile()
  const loading = useSelector(
    (state: RootState) => state.literatureReview.loading
  )
  const literatureReviewList: LiteratureReview[] | undefined = useSelector(
    (state: RootState) => state.literatureReview.literatureReviewList
  )
  const selectedLiteratureReview: LiteratureReview | undefined = useSelector(
    (state: RootState) => state.literatureReview.selected
  )
  const selectedSearchQuery: SearchQuery | undefined = useSelector(
    (state: RootState) => state.literatureReview.selectedSearchQuery
  )
  const selectedDocument: Document | undefined = useSelector(
    (state: RootState) => state.literatureReview.selectedSearchResultItem
  )

  const updateSelectedItems = useCallback(
    (literatureReviewList: LiteratureReview[]) => {
      if (literatureReviewList) {
        const selectedLiteratureReview = literatureReviewList.find(
          (review) => review.id === literatureReviewId
        )
        if (selectedLiteratureReview) {
          dispatch(setSelectedLiteratureReview(selectedLiteratureReview))
        }
        if (searchId) {
          const selectedSearch = selectedLiteratureReview?.searchQueries?.find(
            (query) => query.searchEvent === searchId
          )
          if (selectedSearch) {
            dispatch(setSelectedSearchQuery(selectedSearch))
          }
          if (documentId) {
            const document = selectedSearch?.searchResults?.find(
              (searchResult) => searchResult.id === documentId
            )
            if (document) {
              dispatch(setSelectedSearchResultItem(document))
            }
          }
        }
      }
    },
    [dispatch, literatureReviewId, searchId, documentId]
  )

  const fetchLiteratureReview = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        dispatch(setLiteratureReviewLoading(true))
        apiService
          .fetchItems(urlList.LITERATURE_REVIEWS)
          .then((response: LiteratureReview[]) => {
            dispatch(setLiteratureReviewLoading(false))
            dispatch(setLiteratureReviewList(response))
            updateSelectedItems(response)
          })
          .catch((error: AxiosError | Error) => {
            console.error('axios fetch error', error)
            dispatch(setLiteratureReviewLoading(false))
          })
      }
    },
    [dispatch, loading, updateSelectedItems, preventFetch]
  )

  const refreshLiteratureReviewList = useCallback(() => {
    fetchLiteratureReview(true)
  }, [fetchLiteratureReview])

  useEffect(() => {
    fetchLiteratureReview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSelectedLiteratureReview = (
    selectedLiteratureReview: LiteratureReview
  ) => {
    dispatch(setSelectedLiteratureReview(selectedLiteratureReview))
    const filteredLiteratureReviewList = literatureReviewList
      ? literatureReviewList.filter(
          (literatureReview) =>
            literatureReview.id !== selectedLiteratureReview.id
        )
      : []
    const newLiteratureReviewList = [
      ...filteredLiteratureReviewList,
      selectedLiteratureReview,
    ]
    dispatch(setLiteratureReviewList(newLiteratureReviewList))
  }

  const exportLiteratureReviewListCsv = useCallback(() => {
    if (literatureReviewList) {
      literatureReviewService
        .exportLiteratureReviewList()
        .then((response: string) => {
          const filename = 'literature_review_list.csv'
          downloadCsv(response, filename)
          dispatch(setListCsv(response))
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching literature review list CSV data', error)
        })
    }
  }, [dispatch, downloadCsv, literatureReviewList])

  const exportLiteratureReviewCsv = useCallback(
    (literatureReviewId: string) => {
      if (literatureReviewId) {
        literatureReviewService
          .exportLiteratureReviewToCsv({ literatureReviewId })
          .then((response: string) => {
            const filename = `${selectedLiteratureReview?.title}.csv`
            downloadCsv(response, filename)
            dispatch(setDetailsCsv(response))
          })
          .catch((error: AxiosError) => {
            console.error('Error fetching literature review CSV data', error)
          })
      }
    },
    [dispatch, downloadCsv, selectedLiteratureReview?.title]
  )

  const exportLiteratureReviewSearchQueryCsv = useCallback(
    (literatureReviewId: string, searchQueryId: string) => {
      literatureReviewService
        .exportLiteratureReviewSearchQueryToCsv({
          literatureReviewId,
          searchQueryId,
        })
        .then((response: string) => {
          const filename = `${selectedLiteratureReview?.title}_${selectedSearchQuery?.searchText}_${selectedSearchQuery?.searchDate}.csv`
          downloadCsv(response, filename)
          dispatch(setSearchQueryCsv(response))
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching search query CSV data', error)
        })
    },
    [dispatch, downloadCsv, selectedLiteratureReview, selectedSearchQuery]
  )

  interface SummarizeLiteratureReviewSearchQueryResultProps {
    literatureReviewId: string
    resultId: string
  }

  const summarizeLiteratureReviewSearchQueryResult = useCallback(
    ({
      literatureReviewId,
      resultId,
    }: SummarizeLiteratureReviewSearchQueryResultProps) => {
      dispatch(setLiteratureReviewLoading(true))
      literatureReviewService
        .summarizeSearchQueryResult({ literatureReviewId, resultId })
        .then((response: LiteratureReview) => {
          dispatch(setSelectedLiteratureReview(response))
          dispatch(setLiteratureReviewLoading(false))
        })
        .catch((error: AxiosError) => {
          dispatch(setLiteratureReviewLoading(false))
          console.error('Error fetching search query CSV data', error)
        })
    },
    [dispatch]
  )

  return {
    exportLiteratureReviewListCsv,
    exportLiteratureReviewCsv,
    exportLiteratureReviewSearchQueryCsv,
    literatureReviewList,
    loading,
    refreshLiteratureReviewList,
    selectedDocument,
    selectedLiteratureReview,
    selectedSearchQuery,
    summarizeLiteratureReviewSearchQueryResult,
    updateSelectedLiteratureReview,
  }
}
export default useLiteratureReviews
