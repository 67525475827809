import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DocumentDetailsLayout, SectionLoadingState } from '../../components'
import { DocumentDetailsFormatted } from '../../components/DocumentDetailsLayout'
import { NO_DATA_PLACEHOLDER } from '../../constants/constants'
import { useLiteratureReviews } from '../../hooks'
import literatureReviewService from '../../services/literatureReviewService'
import notificationService from '../../services/notificationService'
import { LiteratureReview } from '../../store/reducers/literatureReviewReducer'
import { routePaths } from '../RootPage'

const LiteratureReviewSearchDocumentDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    selectedLiteratureReview,
    selectedSearchQuery,
    selectedDocument,
    updateSelectedLiteratureReview,
  } = useLiteratureReviews(params)
  const [inclusionSwitchLoading, setInclusionSwitchLoading] = useState(false)

  const getDocumentIndex = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? selectedSearchQuery.searchResults.indexOf(selectedDocument)
        : 0,
    [selectedDocument, selectedSearchQuery?.searchResults]
  )
  const getDocumentIndexText = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? getDocumentIndex() + 1
        : NO_DATA_PLACEHOLDER,
    [selectedDocument, selectedSearchQuery?.searchResults, getDocumentIndex]
  )

  const handlePreviousDocumentClick = () => {
    const lastIndex = selectedSearchQuery?.searchResults
      ? selectedSearchQuery?.searchResults.length - 1
      : 0
    const documentIndex = getDocumentIndex()
    const newDocumentIndex = documentIndex > 0 ? documentIndex - 1 : lastIndex
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    navigate(
      `${routePaths.LITERATURE_REVIEW_DOCUMENT}/${selectedLiteratureReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const handleNextDocumentClick = () => {
    const documentIndex = getDocumentIndex()
    const newDocumentIndex =
      selectedSearchQuery?.searchResults &&
      documentIndex < selectedSearchQuery?.searchResults.length - 1
        ? documentIndex + 1
        : 0
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    navigate(
      `${routePaths.LITERATURE_REVIEW_DOCUMENT}/${selectedLiteratureReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const isDocumentIncluded = useMemo(
    () =>
      selectedLiteratureReview?.includedSearchResults?.includes(
        selectedDocument?.id || ''
      ),
    [selectedDocument, selectedLiteratureReview?.includedSearchResults]
  )

  const handleSwitchClick = () => {
    setInclusionSwitchLoading(true)
    literatureReviewService
      .saveSearchQueryInclusion({
        literatureReviewId: selectedLiteratureReview?.id || '',
        includedSearchResults:
          selectedLiteratureReview?.includedSearchResults || [],
        recordId: selectedDocument?.id || '',
        navigate: navigate,
      })
      .then((response: LiteratureReview) => {
        updateSelectedLiteratureReview(response)
        setInclusionSwitchLoading(false)
        notificationService.notificationSuccess(
          t(
            'literatureReviewSearchDocumentDetailsPage.saveDocumentSuccessful'
          ) || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setInclusionSwitchLoading(false)
      })
  }

  const formattedDocumentDetails: DocumentDetailsFormatted = useMemo(
    () => ({
      _id: selectedDocument?.id,
      authors: selectedDocument?.authors,
      dataSource: selectedDocument?.dataSource,
      originalPublicationDate: selectedDocument?.publicationDate || '',
      language: selectedDocument?.language || '',
      publicationName: selectedDocument?.publicationName || '',
      title: selectedDocument?.title || NO_DATA_PLACEHOLDER,
      url: selectedDocument?.documentLink,
      csv: selectedDocument?.csv,
    }),
    [selectedDocument]
  )

  return selectedDocument ? (
    <DocumentDetailsLayout
      handleNextDocumentClick={handleNextDocumentClick}
      handlePreviousDocumentClick={handlePreviousDocumentClick}
      getDocumentIndex={getDocumentIndexText}
      length={selectedSearchQuery?.searchResults.length}
      document={formattedDocumentDetails}
      includedSwitchValue={isDocumentIncluded}
      onSwitchClick={handleSwitchClick}
      switchLoading={inclusionSwitchLoading}
      backUrl={`${routePaths.LITERATURE_REVIEW_SEARCH}/${selectedLiteratureReview?.id}/${selectedSearchQuery?.id}`}
    />
  ) : (
    <SectionLoadingState />
  )
}

export default LiteratureReviewSearchDocumentDetailsPage
