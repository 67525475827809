import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CopyOutlined,
  HistoryOutlined,
  InboxOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { Button, Form, Tooltip } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../constants/constants'
import { useDownloadFile } from '../hooks'
import notificationService from '../services/notificationService'
import PageContentDropdown from './PageContentDropdown'
import SimpleSwitch from './SimpleSwitch'
import DetailsPageLayout from './display/DetailsPageLayout'
import IconTextContainer from './display/IconTextContainer'
import MultiColumnFormColumnContainer from './display/MultiColumnFormColumnContainer'
import MultiColumnFormContentContainer from './display/MultiColumnFormContentContainer'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import Spacer from './display/Spacer'

const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`

const UrlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FormItemText = styled.div`
  margin-left: 8px;
`

export interface DocumentDetailsFormatted {
  _id?: string
  authors?: string[]
  dataSource?: string
  language?: string
  originalPublicationDate?: string
  publicationName?: string
  title?: string
  url?: string
  csv?: string
}

interface DocumentDetailsLayoutProps {
  handleNextDocumentClick: () => void
  handlePreviousDocumentClick: () => void
  getDocumentIndex: () => string | number
  length: number | undefined
  document: DocumentDetailsFormatted
  includedSwitchValue?: boolean
  onSwitchClick?: (id: string) => void
  switchLoading?: boolean
  backUrl: string
}

const DocumentDetailsLayout = ({
  handleNextDocumentClick,
  handlePreviousDocumentClick,
  getDocumentIndex,
  length,
  document,
  includedSwitchValue,
  onSwitchClick,
  switchLoading,
  backUrl,
}: DocumentDetailsLayoutProps) => {
  const navigate = useNavigate()
  const downloadCsv = useDownloadFile()

  const formattedIndexText = useMemo(() => {
    const indexText = getDocumentIndex()
    return typeof indexText === 'string'
      ? indexText
      : indexText.toString().padStart(2, '0')
  }, [getDocumentIndex])

  const copyToClippboard = () => {
    navigator.clipboard.writeText(document.url || '')
    notificationService.notificationSuccess(
      t('documentDetailsLayout.urlToClipboard')
    )
  }

  const closeDetails = () => {
    navigate(backUrl)
  }

  const handleExportClick = () => {
    const filename = `${document.title || 'document'}_export.csv`
    if (document?.csv) downloadCsv(document?.csv, filename)
  }

  return (
    <DetailsPageLayout>
      <Form layout="vertical">
        <SectionHeaderContainer>
          <Tooltip
            title={t('documentDetailsLayout.backToSearchResultsTooltip')}
            placement="right"
          >
            <Button icon={<CloseOutlined />} onClick={closeDetails} />
          </Tooltip>
          <Tooltip
            title={t('documentDetailsLayout.previousDocumentTooltip')}
            placement="right"
          >
            <Button
              icon={<ArrowUpOutlined />}
              onClick={handlePreviousDocumentClick}
            />
          </Tooltip>
          <Tooltip
            title={t('documentDetailsLayout.nextDocumentTooltip')}
            placement="right"
          >
            <Button
              icon={<ArrowDownOutlined />}
              onClick={handleNextDocumentClick}
            />
          </Tooltip>
          <InboxOutlined />
          <div>
            {t('documentDetailsLayout.title', {
              index: formattedIndexText,
              total: length,
            })}
          </div>
          {onSwitchClick && (
            <SimpleSwitchContainer>
              <SimpleSwitch
                checked={includedSwitchValue}
                onClick={() =>
                  onSwitchClick && onSwitchClick(document._id || '')
                }
                loading={switchLoading}
              />
              {includedSwitchValue ? t('Included') : t('Excluded')}
            </SimpleSwitchContainer>
          )}
          <Spacer />
          <Button
            style={{ marginLeft: '8px' }}
            htmlType="submit"
            href={document?.url || ''}
            target="_blank"
            icon={<LinkOutlined />}
          >
            {t('documentDetailsLayout.launchLink')}
          </Button>
          <PageContentDropdown onExport={handleExportClick} />
        </SectionHeaderContainer>
        <MultiColumnFormContentContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item label={t('Title')}>
              <FormItemText>
                {document?.title || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Provenance')}>
              <FormItemText>
                {document.publicationName || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Authors')}>
              <FormItemText>
                {document?.authors?.join(', ') || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item
              label={t('documentDetailsLayout.originalPublicationDate')}
            >
              <FormItemText>
                <IconTextContainer>
                  <HistoryOutlined />
                  <Moment
                    date={document?.originalPublicationDate}
                    format={dateFormat.PRIMARY}
                  />
                </IconTextContainer>
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Language')}>
              <FormItemText>
                {document?.language
                  ? t(`${document.language}`)
                  : NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
          </MultiColumnFormColumnContainer>
          <MultiColumnFormColumnContainer>
            <UrlContainer>
              <Form.Item label={t('Link')}>
                <FormItemText>
                  {document?.url ? document.url : NO_DATA_PLACEHOLDER}
                </FormItemText>
              </Form.Item>

              <Button
                onClick={copyToClippboard}
                type="text"
                icon={<CopyOutlined />}
              />
            </UrlContainer>
          </MultiColumnFormColumnContainer>
        </MultiColumnFormContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default DocumentDetailsLayout
